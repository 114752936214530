<template>
  <div>
    <!-- <new-invest
      ref="investment"
      @onSuccess="getInvestmentsInfo"
    /> -->
    <b-row>
      <b-col cols="4">
        <b-card
          v-if="investor"
          class="earnings-card"
        >
          <p>
            <b>FIO</b>:
            <span class="font-weight-bold">
              {{
                [
                  investor.lastname,
                  investor.firstname,
                  investor.middlename,
                ].join(" ")
              }}</span>
          </p>
          <p><b>Telefoni</b>: +998{{ investor.phone }}</p>
          <p>
            <b>Manzili</b>:
            <span v-if="investor.region">
              {{
                investor.region.name_uz +
                  ", " +
                  investor.city.name_uz +
                  ", " +
                  investor.address
              }}
            </span>
          </p>
          <p><b>PINFL</b>: {{ investor.pin }}</p>
          <p><b>Passport</b>: {{ investor.passport }}</p>
          <p>
            <b> Umumiy foydadan investor ulushi foizi</b>:
            {{ investor.profit_percent }}%
          </p>
        </b-card>
      </b-col>
      <b-col cols="8">
        <b-card class="earnings-card">
          <b-row>
            <b-col cols="6">
              <h3>Investitsiyalar grafigi</h3>
            </b-col>
            <b-col cols="6">
              <b-button
                variant="primary"
                class="float-right"
                @click="addInvest()"
              >
                <feather-icon icon="PlusIcon" />
                Investitsiya kiritish
              </b-button>
            </b-col>
          </b-row>
          <b-table
            v-if="investments && investments.length"
            bordered
            hover
            :items="investments"
            :fields="fields"
          >
            <template #cell(number)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(date)="data">
              {{ dateDotFormat(data.item.created_at) }}
            </template>
            <template #cell(contract_url)="data">
              <a
                :href="storageUrl+ data.item.contract_url"
                target="_blank"
              >
                <feather-icon icon="PaperclipIcon" />
                Kontrakt</a>
            </template>
            <template #cell(amount)="data">
              <p>  {{ data.item.amount_dollar | formatPrice }} $</p>
              {{ data.item.amount | formatPrice }} so'm
            </template>
          </b-table>
          <div v-else>
            <span class="text-muted">Malumot topilmadi</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      investments: [],
      fields: [
        {
          key: 'number',
          label: '№',
        },
        {
          key: 'amount',
          label: 'Сумма',
        },
        {
          key: 'contract_url',
          label: 'Kontrakt',
        },
        {
          key: 'date',
          label: 'Дата',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ investor: 'investment/GET_ITEM' }),
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
  },
  mounted() {
    this.getInvestorInfo()
    this.getInvestmentsInfo()
  },
  methods: {
    ...mapActions({ getItem: 'investment/show', investmentsIndex: 'investment/investmentsIndex' }),
    getInvestorInfo() {
      this.getItem({ id: this.$route.params.id, relations: 'region|city' })
    },
    openPdf(link) {
      window.open(link, '_blank')
    },
    async getInvestmentsInfo() {
      await this.investmentsIndex({ investor_id: this.$route.params.id, per_page: 9999 }).then(res => {
        this.investments = res.data.data
      })
    },
    addInvest() {
      this.$refs.investment.visible = true
    },
  },
}
</script>

<style></style>
